import { Container, Box } from '@mui/material'
import React from 'react'
// import ClientSection from './components/hipros/ClientsSection'
import ServiceSection from './components/hipros/ServiceSection'

export default function Services() {
    return (
        <Box>
            <ServiceSection />
        </Box>
    )
}
