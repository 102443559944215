import React, { useState } from 'react';
import { Box, Button, Grid, Typography, Container, CardContent } from '@mui/material';
import CustomizedButtons from 'src/components/Button/CustomizedButtons';
import CustomCard from './CustomCard';
import CustomText from 'src/components/Text/CustomText';
import { useNavigate } from 'react-router';
import { useLanding } from 'src/contexts/LandingContext';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';



const ServiceSection = () => {
    const [services, setServices] = useState([
        'Digital Ads',
        'Affiliator Management',
        'Content Creation',
        'Live Streaming',
        'KOL Management',
        'E-Commerce Enabler',
        'Brand  Activation',
        'TVC And Photo Production',
        'KOL Management',
        'Apps Development',
        'Website Development',

    ])
    const { isMobile, kirimPesanWhatsApp } = useLanding();


    const [leftColumnRef, leftColumnInView] = useInView({
        triggerOnce: true,
        threshold: 0.2,    // 20% dari elemen harus terlihat sebelum animasi dijalankan
    });

    const [rightColumnRef, rightColumnInView] = useInView({
        triggerOnce: true,
        threshold: 0.2,
    });

    // Variabel animasi untuk elemen-elemen
    const cardVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
    };

    return (

        <Box style={{ background: '#202020', paddingTop: isMobile ? 0 : '200px', paddingBottom: '100px' }}>
            <Container style={{
                position: 'relative'
            }}>
                <Box textAlign="center" mb={3}>
                    <CustomText type="h1" >
                        <span className='default-text-shadow'>Our Service</span>
                    </CustomText>
                </Box>
                <Grid container spacing={2}>
                    {/* Left Column */}
                    <Grid item xs={12} md={6} ref={leftColumnRef}>
                        {services.slice(0, 6).map((value, index) => (

                            <motion.div
                                key={index}
                                initial="hidden"
                                animate={leftColumnInView ? "visible" : "hidden"}
                                variants={cardVariants}
                            >
                                <CustomCard style={{ marginBottom: '35px', display: 'flex', justifyContent: 'start' }}>
                                    <CardContent>
                                        <CustomText type="b" style={{ color: 'black', }}>{value}</CustomText>
                                    </CardContent>
                                </CustomCard>
                            </motion.div>
                        ))}
                    </Grid>
                    <Grid item xs={12} md={6} ref={leftColumnRef} mt={!isMobile ? 0 : 5}>
                        {services.slice(6, 12).map((value, index) => (

                            <motion.div
                                key={index}
                                initial="hidden"
                                animate={leftColumnInView ? "visible" : "hidden"}
                                variants={cardVariants}
                            >
                                <CustomCard
                                    onClick={() => kirimPesanWhatsApp("Hallo saya ingin bertanya tentang jasa " + value, "")}

                                    style={{ marginBottom: '35px', display: 'flex', justifyContent: 'start', cursor: 'pointer' }}>
                                    <CardContent>
                                        <CustomText type="b" style={{ color: 'black', }}>{value}</CustomText>
                                    </CardContent>
                                </CustomCard>
                            </motion.div>
                        ))}
                    </Grid>
                </Grid>
                <Box position="absolute" style={{
                    top: 0,
                    left: '15%'
                }}>
                    {!isMobile && (<img src="/static/images/background/hipros-background-icon-client.png" alt="" width="800px" />)}
                </Box>
            </Container>
        </Box>

    );
};

export default ServiceSection;
