import { Card } from "@mui/material";

const CustomCard = ({ children, ...props }) => {



    return (
        <Card
            sx={{

                backgroundColor: '#fff',
                borderRadius: '30px 5px 30px 5px',
                boxShadow: '6px 8px 0px #FDD12A',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 10px',
            }}
            {...props}
        >
            {children}
        </Card>
    );
};
export default CustomCard